import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { MdCancel as DeleteIcon } from 'react-icons/md'
// import { MdSearch as SearchIcon } from 'react-icons/md'
import styles from './LineItem.module.scss'
import SelectSearch from 'react-select-search';
import './select-search-style.css'
import { Tooltip } from "shards-react";

class LineItem extends Component {
  state = {
    // modalOpen: false
    tooltipOpen: false
  }

  componentDidMount() {
    // this.modalToggle = this.modalToggle.bind(this);
    this.ttToggle = this.ttToggle.bind(this);
  }

  // modalToggle() {
  //   this.setState({modalOpen: !this.state.modalOpen});
  // }

  ttToggle() {
    this.setState({tooltipOpen: !this.state.tooltipOpen});
  }

  renderGlOptions(props, option, snapshot, className) {
    return (
        <button {...props} className={className} type="button">
            <span>{option.name}</span>
        </button>
    );
  }

  render = () => {
    // const {modalOpen} = this.state;
    const { index, item_id, name, description, owner_desc, gl_acct, gl_acct_name, quantity, price, markup } = this.props

    return (
      <div className={this.props.pgid == 'dd_76351TFD' ? styles.lineItemMgr : styles.lineItem}>
        {/* <div>{index + 1}</div> */}
        {/* <div><input name="name" type="text" value={name} onChange={this.props.changeHandler(index)} /></div> */}

        {this.props.pgid != 'dd_76351TFD' ?
        <div><input name="description" type="text" value={description} onChange={this.props.changeHandler(index)} /></div>
        :<div><input name="owner_desc" type="text" value={owner_desc} onChange={this.props.changeHandler(index)} /></div>}

        {/* <div><input name="gl_acct" type="text" value={gl_acct} onChange={this.props.changeHandler(index)} />
          <button type="button"
            className={styles.deleteItem}
            onClick={this.modalToggle}
          ><SearchIcon size="1.25em" /></button>
        </div> */}
        {this.props.pgid != 'dd_76351TFD' ?
        <div><SelectSearch id={'glss'+index} search getOptions={this.props.glOptions} value={gl_acct}
                renderOption={this.renderGlOptions}
                placeholder="Select GL Account"
                onChange={this.props.glChange("gl_acct", index)}
                options={[{name:gl_acct_name, value:gl_acct}]}
              />
              <Tooltip trigger="hover"
                open={this.state.tooltipOpen}
                target={'#glss'+index}
                toggle={() => this.ttToggle()}>{gl_acct + '-' + gl_acct_name}</Tooltip>
        </div>
        :
        <div>{gl_acct_name}</div>}
        {/* <div><input name="quantity" type="number" step="1" value={quantity} onChange={this.props.changeHandler(index)} onFocus={this.props.focusHandler} /></div> */}
        {this.props.pgid != 'dd_76351TFD' ?
        // <div className={styles.currency}><input name="price" type="number" step="0.01" min="0.00" max="9999999.99" value={price} onChange={this.props.changeHandler(index)} onFocus={this.props.focusHandler} />
        <div className={styles.currency}><input name="price" type="number" value={price} onChange={this.props.changeHandler(index)} onFocus={this.props.focusHandler} />
          <span>{markup > 0 ? '+'+markup : ''}</span></div>:
        <div>${price}</div>}
        {/* <div className={styles.currency}>{this.props.currencyFormatter( quantity * price )}</div> */}
        {this.props.pgid == 'dd_76351TFD' ?
        <div className={styles.currency}><input name="markup" type="number" step="0.01" min="0.00" max="9999999.99" value={markup} onChange={this.props.changeHandler(index)} onFocus={this.props.focusHandler} /></div>
        :
        <div>
          <button type="button"
            className={styles.deleteItem}
            onClick={this.props.deleteHandler(index, item_id)}
          ><DeleteIcon size="1.25em" /></button>
        </div>
        }
        {/* <Modal open={modalOpen} toggle={this.modalToggle}>
          <ModalHeader>Specify Reason</ModalHeader>
          <ModalBody>
          <SelectSearch search getOptions={this.props.glOptions} value={gl_acct}
                placeholder="Select GL Account"
                onChange={this.props.glChange("gl_acct", index)}
                options={[{name:gl_acct_name, value:gl_acct}]}
              />
          <Button className={styles.payNow} onClick={this.handleRejectButtonClick}>
            {this.state.pgid == 'dd_77987F15' ?
              'Deactivate':'Reject'}
          </Button>
          </ModalBody>
        </Modal> */}

      </div>
    )
  }
}

export default LineItem

LineItem.propTypes = {
  pgid: PropTypes.string,
  index: PropTypes.number.isRequired,
  item_id: PropTypes.number.isRequired,
  name: PropTypes.string,
  description: PropTypes.string,
  owner_desc: PropTypes.string,
  gl_acct: PropTypes.string,
  quantity: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  markup: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}
