import React, { Fragment, useState, useEffect, useCallback } from "react";
import axios from "axios";
import {
  Button,
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  ListGroup,
  ListGroupItem,
  Form,
  FormInput,
  FormSelect,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Alert
} from "shards-react";
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';

import PageTitle from "../components/common/PageTitle";
import Forms from "../components/components-overview/Forms";
import FormValidation from "../components/components-overview/FormValidation";
import Sliders from "../components/components-overview/Sliders";
import ProgressBars from "../components/components-overview/ProgressBars";
import ButtonGroups from "../components/components-overview/ButtonGroups";
import InputGroups from "../components/components-overview/InputGroups";
import SeamlessInputGroups from "../components/components-overview/SeamlessInputGroups";
import CustomFileUpload from "../components/components-overview/CustomFileUpload";
import DropdownInputGroups from "../components/components-overview/DropdownInputGroups";
import CustomSelect from "../components/components-overview/CustomSelect";
import Invoice from "../invoice/Invoice"
import InvoicePortal from "./InvoicePortal";
import {checkVendorPdfType} from "../utils/Commons";
import SortTable from "../components/sortable/SortTable";

import "../assets/dealminder.css"

function DirectorApproval(props) {
  const pgid = 'dd_76841TBD';
  const DD_API_HOST = process.env.REACT_APP_DD_API_HOST;
  const columns = [
    { label: "Date Received", accessor: "bill_date" },
    { label: "Vendor", accessor: "vendor_name" },
    { label: "Invoice #", accessor: "vendor_inv_number" },
    { label: "Property", accessor: "unit_address" },
    { label: "Amount", accessor: "totals" },
    { label: "Status", accessor: "status" },
  ];  
  const [lineItems, setLineItems] = useState([]);
  const [invHeader, setInvHeader] = useState({vendor: ""});
  const [refreshList, setRefreshList] = useState(0);
  const [pdfUrl, setPdfUrl] = useState('');
  const [portalOpen, setPortalOpen] = useState(false);
  const [pdfType, setPdfType] = useState('A');
  const [propAddr, setPropAddr] = useState('');
  const [amtdue, setAmtdue] = useState('');
  const [duedate, setDuedate] = useState('');
  const [acctglcode, setAcctglcode] = useState('');
  const [deals, setDeals] = useState([]);
  const [invoices, setInvoices] = useState([]);
  const [click, setClick] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageScale, setPageScale] = useState(1.0);
  const documentWrapperRef = React.useRef();
  const invoiceHatch = React.createRef();

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const fetchInvoices = async () => {
    setIsLoading(true);
    setErrorMsg('');
    try {
    let p = sessionStorage.getItem('ptid');
    let rt = sessionStorage.getItem('rtok');
    const result = await axios(
      DD_API_HOST+'/ap_invoices?cid='+p+'&sid='+pgid+'&rt='+rt,
    );
    console.log(result);
    if (result.data.error) {
      // alert(result.data.error);
      setErrorMsg(result.data.error);
    } else {
      let lst_inv = result.data.invoices.map(function(x){
        let y = {};
        y['id'] = x.id; // id is always passed along
        columns.forEach((e) => y[e.accessor] = x[e.accessor]);
        return  y;      }
      );
      setInvoices(lst_inv);
      setLineItems([]);
      setInvHeader({vendor: ""});
      //console.log(deals);  
    }
    setIsLoading(false);
    } catch(error) {
      console.log(error);
    }
  };

  const fetchInv = async (selected_inv) => {
    if (invoiceHatch.current.state.editing === true)
      if (!window.confirm("Editing Invoice. Load Anyway?")) {
        console.log('i aint loading');
        return;
      } else {
        invoiceHatch.current.overrideEditing(false);
      }

    setIsLoading(true);
    setErrorMsg('');
    let rt = sessionStorage.getItem('rtok');
    try {
    const result = await axios(
      DD_API_HOST+'/ap_pdf?ref=' + selected_inv.id 
        + '&iu=' + selected_inv.filename
        + '&rt=' + rt,
    );
    console.log(result);
    if (result.data.error) {
      // alert(result.data.error);
      setErrorMsg(result.data.error);
      setPdfUrl('');
      setLineItems([]);
      setInvHeader({vendor: ""});
    } else {
      setPdfUrl(DD_API_HOST+'/invoice/'+selected_inv.filename+'?tok=4234234');
      setLineItems(result.data.line_items);
      // We have to do this until we figure out a better way
      checkVendorPdfType(selected_inv.vendor_entity_id, setPdfType);
      setInvHeader({
        id: selected_inv.id,
        vendor: selected_inv.vendor_entity_id,
        vendor_name: selected_inv.vendor_name,
        property: selected_inv.unit_entity_id,
        property_addr: selected_inv.unit_address,
        wo: selected_inv.wo_entity_id,
        wo_number: selected_inv.wo_number,
        gl_account: selected_inv.gl_account,
        gl_account_name: selected_inv.gl_account_name,
        vendor_inv_number: selected_inv.vendor_inv_number,
        bill_date: new Date(selected_inv.bill_date+'T00:00:00'),
        bill_due_date: new Date(selected_inv.bill_due_date+'T00:00:00'),
        notes: selected_inv.notes,
        new_notes: '',
        filename: selected_inv.filename
      });
      //console.log(pdfUrl);
    }
    setIsLoading(false);
    } catch(error) {
      console.log(error);
    }
  };

  function incrRefresh() {
    setRefreshList(refreshList+1);
  }

  function downloadPdf() {
    if (invHeader.filename === undefined) {
      alert("No invoice loaded yet");
      return
    }
    const FileDownload = require('js-file-download');
    fetch(DD_API_HOST+'/invoice/'+invHeader.filename+'?tok=4234234',
    { 
      method: "GET",
      headers: { "Content-Type": "application/json"},
    }).then(response => response.blob()).then(response => {
      FileDownload(response, invHeader.filename);
    });
  }

  function openPortal() {
    if (invoiceHatch.current.state.invHeader.vendor != undefined)
      checkVendorPdfType(invoiceHatch.current.state.invHeader.vendor, setPdfType);

    setPortalOpen(!portalOpen);
    console.log("portalOpen:"+portalOpen);
  }

  const changePage = useCallback(
    (offset) => setPageNumber((prevPageNumber) => (prevPageNumber || 1) + offset),
    [],
  );

  const previousPage = useCallback(() => changePage(-1), [changePage]);

  const nextPage = useCallback(() => changePage(1), [changePage]);

  const changePageScale = useCallback(
    (offset) => setPageScale((prevPageScale) => (prevPageScale || 1) + offset),
    [],
  );

  const zoomOut = useCallback(() => changePageScale(-1.0), [changePageScale]);

  const zoomIn = useCallback(() => changePageScale(1.0), [changePageScale]);

  useEffect(() => {
    //fetchData();
    fetchInvoices();
  }, [refreshList]);

  return (
    <div>
    <Container fluid className="px-0">
      { errorMsg === '' ? (
        <span/>
      ) : (
        <Alert className="mb-0">
        <i className="fa fa-info mx-2"></i> {errorMsg}
        </Alert>
      )}
    </Container>
    <Container fluid className="main-content-container px-4">
      <Row noGutters className="page-header py-4">
        <PageTitle
          sm="4"
          title="Review Invoices"
          subtitle="Account Payable Workflow"
          className="text-sm-left"
        />
      </Row>

      <Row>
        <Col lg="8" className="mb-4">
          <Invoice ref={invoiceHatch} header={invHeader} lis={lineItems}
            pgid={pgid} readonly={true}
            incrRefresh={incrRefresh}/>
          {/* <Card className="mb-4">
          <div>
          </div>
          </Card> */}
          <Card small className="mb-4">
            <CardHeader className="border-bottom">
              {/* <h6 className="m-0">{productName}</h6> */}
            </CardHeader>
            <CardBody className="p-0 pb-3">
            {isLoading ? (
              <div className="is_loading_true">Searching ...</div>
            ) : (
              <SortTable columns={columns} data={invoices}
              fetchInv={fetchInv} />
              // <table className="table mb-0">
              //   <thead className="bg-light">
              //     <tr>
              //       <th scope="col" className="border-0">
              //         Date Received
              //       </th>
              //       <th scope="col" className="border-0">
              //         Vendor
              //       </th>
              //       <th scope="col" className="border-0">
              //         Invoice #
              //       </th>
              //       <th scope="col" className="border-0">
              //         Property
              //       </th>
              //       <th scope="col" className="border-0">
              //         Amount
              //       </th>
              //       <th scope="col" className="border-0">
              //         Status
              //       </th>
              //     </tr>
              //   </thead>
              //   <tbody>
              //     {deals.map((item,i) => {
              //       return (
              //         <Fragment>
              //           <tr onClick={() => {
              //               fetchInv(item);
              //             }} key={item.id}>
              //             <td title={item.id}>{item.bill_date}</td>
              //             <td>{item.vendor_name}</td>
              //             <td>{item.vendor_inv_number}</td>
              //             <td>{item.unit_address}</td>
              //             <td>{item.totals}</td>
              //             <td>{item.status}</td>
              //           </tr>
              //         </Fragment>
              //       )
              //     })}
              //   </tbody>
              // </table>
            )}
            </CardBody>
          </Card>

        </Col>

        <Col lg="4" className="mb-4">
          <Card small className="mb-4">
            {/* The file should point to a URL */}
            {/*file="/inv1.pdf"*/}
            <Document
              file={pdfUrl}
              onLoadSuccess={onDocumentLoadSuccess}
            >
              <Page 
                //width={documentWrapperRef.current && documentWrapperRef.current.getBoundingClientRect().width}
                scale={pageScale}
                width="300"
                pageNumber={pageNumber} />
            </Document>
            <p>Page {pageNumber} of {numPages}</p>
            <div>
                  <Button
                    size="sm"
                    disabled={pageScale <= 1}
                    onClick={zoomOut}
                    type="button"
                  >
                    Zoom Out
                  </Button>
                  <span>
                    &nbsp;Scale: {pageScale}x&nbsp;
                  </span>
                  <Button
                    size="sm"
                    disabled={pageScale >= 5.0}
                    onClick={zoomIn}
                    type="button"
                  >
                    Zoom In
                  </Button>
            </div>              
            <Button onClick={openPortal}>
              {portalOpen ? 'Close the' : 'Open a'} separate Window
            </Button>
            {portalOpen && (
            <InvoicePortal>
              <Document
                file={pdfUrl}
                onLoadSuccess={onDocumentLoadSuccess}
                options={pdfType == 'A' ? {disableFontFace: true}:{disableFontFace: false}}
              >
                <div>
                  <button
                    disabled={pageNumber <= 1}
                    onClick={previousPage}
                    type="button"
                  >
                    Previous
                  </button>
                  <span>
                    {`Page ${pageNumber || (numPages ? 1 : '--')} of ${numPages || '--'}`}
                  </span>
                  <button
                    disabled={pageNumber >= numPages}
                    onClick={nextPage}
                    type="button"
                  >
                    Next
                  </button>
                </div>              

                <Page 
                  //width={documentWrapperRef.current && documentWrapperRef.current.getBoundingClientRect().width}
                  width="1000"
                  pageNumber={pageNumber} />
              </Document>
            </InvoicePortal>
            )}
          </Card>

          {/* Files & Dropdowns */}
          <Card small className="mb-4">
            <CardHeader className="border-bottom">
              <h6 className="m-0">Download PDF</h6>
            </CardHeader>

            <ListGroup flush>
              <ListGroupItem className="px-3">
                <Button onClick={downloadPdf}>
                  Download
                </Button>
              </ListGroupItem>
            </ListGroup>
          </Card>

        </Col>
      </Row>
    </Container>
  </div>
  );  
}

export default DirectorApproval;
